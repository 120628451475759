"use client";
import React, { createContext, useContext, useEffect, useMemo, useState } from "react";
import { SelectLists } from "@/interfaces";
import { GET_ALL_DATE_FRECUENCY, GET_CALENDAR_TYPE, GET_COUNTRIES, GET_ELIGIBLE_COVER, GET_ENTITY_TYPE, GET_EVENT_TYPES, GET_PERMISSIONS, GET_PERMISSIONS_USER, GET_REMINDER_PERIOD, GET_REQUEST_EVENT_STATUS, GET_RESOURCES, GET_ROLE_CATEGORIES, GET_ROLE_CATEGORIES_EXCLUDE_ADMIN, GET_SALE_STATUS, GET_SALE_TYPE, GET_STATUS_APPOINTMENT, GET_STATUS_EVENT } from "@/lib/keys";
import { GET_SOURCE_BRC } from '../lib/keys';
import { findPermissionsByRole } from "@/lib/Apis";
import { useSession } from "next-auth/react";
import { useApiError } from "@/hooks/useApiError";

const CommonContext = createContext<any>(undefined);

interface ListProps {
  countries: SelectLists[];
  eventType: SelectLists[];
  resources: SelectLists[];
  statusAppointment: SelectLists[];
  entityType: SelectLists[];
  statusEvent: SelectLists[];
  requestEventStatus: SelectLists[];
  frecuency: SelectLists[];
  saleType: SelectLists[];
  sourceBrc: SelectLists[];
  eligibleCover: SelectLists[];
  reminderPeriod: SelectLists[];
  saleStatus: SelectLists[];
  calendarType: SelectLists[];
  permissions: SelectLists[];
  permissionsUser: SelectLists[];
  roleCategories: SelectLists[];
  roleCategoriesExcludeAdmin: SelectLists[];
}

export default function CommonContextProvider({ children }: Readonly<{
  children: React.ReactNode;
}>) {
  const { data: session }: any = useSession();
  const [promotorId, setPromotorId] = useState<string>("");
  const [sellerId, setSellerId] = useState<string>("");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [sellerCache, setSellerCache] = useState<{ [key: string]: any }>({});
  const [lists, setLists] = useState<ListProps>({
    countries: [],
    eventType: [],
    resources: [],
    statusAppointment: [],
    entityType: [],
    statusEvent: [],
    requestEventStatus: [],
    frecuency: [],
    saleType: [],
    sourceBrc: [],
    eligibleCover: [],
    reminderPeriod: [],
    saleStatus: [],
    calendarType: [],
    permissions: [],
    permissionsUser: [],
    roleCategories: [],
    roleCategoriesExcludeAdmin: [],
  });
  const { getApiError } = useApiError();

  const getList = (key: string, data: SelectLists[]) => {
    switch (key) {
      case GET_COUNTRIES:
        lists.countries = data;
        break;
      case GET_EVENT_TYPES:
        lists.eventType = data;
        break;
      case GET_RESOURCES:
        lists.resources = data;
        break;
      case GET_STATUS_APPOINTMENT:
        lists.statusAppointment = data;
        break;
      case GET_ENTITY_TYPE:
        lists.entityType = data;
        break;
      case GET_STATUS_EVENT:
        lists.statusEvent = data;
        break;
      case GET_REQUEST_EVENT_STATUS:
        lists.requestEventStatus = data;
        break;
      case GET_ALL_DATE_FRECUENCY:
        lists.frecuency = data;
        break;
      case GET_SALE_TYPE:
        lists.saleType = data;
        break;
      case GET_SOURCE_BRC:
        lists.sourceBrc = data;
        break;
      case GET_ELIGIBLE_COVER:
        lists.eligibleCover = data;
        break;
      case GET_REMINDER_PERIOD:
        lists.reminderPeriod = data;
        break;
      case GET_SALE_STATUS:
        lists.saleStatus = data;
        break;
      case GET_CALENDAR_TYPE:
        lists.calendarType = data;
        break;
      case GET_PERMISSIONS:
        lists.permissions = data;
        break;
      case GET_PERMISSIONS_USER:
        lists.permissionsUser = data;
        break;
      case GET_ROLE_CATEGORIES:
        lists.roleCategories = data;
        break;
      case GET_ROLE_CATEGORIES_EXCLUDE_ADMIN:
        lists.roleCategoriesExcludeAdmin = data;
        break;
      default:
        break;
    }
    setLists(lists);
  }

  const getPermissions = async () => {
    try {
      const data = await findPermissionsByRole(session?.user?.role_id);
      if (typeof data === 'string') {
        return getApiError(data);
      }
      getList(GET_PERMISSIONS_USER, data);
    } catch (error: any) {
      getApiError(error)
    }
  }

  const values = useMemo(
    () => ({
      setLists,
      lists,
      getList,
      setPromotorId,
      promotorId,
      setSellerId,
      sellerId,
      setShowFilter,
      showFilter,
      setSellerCache,
      sellerCache,
      getPermissions
    }),
    [
      lists,
      getList,
      promotorId,
      sellerId,
      setShowFilter,
      showFilter,
      sellerCache
    ]
  );

  return <CommonContext.Provider value={values}>{children}</CommonContext.Provider>;
}
export function useCommonContext() {
  return useContext(CommonContext);
}
